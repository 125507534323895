import { IsNumber, IsOptional } from 'class-validator';
export class ProfileCategoryGetListRequest {
    @IsNumber()
    @IsOptional()
    public userId?: number;

    constructor(data?: Partial<ProfileCategoryGetListRequest>) {
        this.userId = data && data.userId;
    }
}
