import { ArrayNotEmpty, IsIn, IsInt } from 'class-validator';

import { OrderStatusTxt } from '../order-status';

export type OrderPositionListRequestRaw = {
    areaId: string | number;
    orderStatusList: OrderStatusTxt[];
};

export class OrderPositionListRequest {
    @IsInt()
    public areaId: number;

    @IsIn(['new', 'work', 'closed', 'shipped'], { each: true })
    @ArrayNotEmpty()
    public orderStatusList: OrderStatusTxt[];

    constructor(data?: Partial<OrderPositionListRequestRaw>) {
        this.areaId = typeof data?.areaId === 'number' ? data.areaId : parseInt(data?.areaId, 10);
        this.orderStatusList = data?.orderStatusList;
    }
}
