import { Observable, map } from 'rxjs';

import { Injectable } from '@angular/core';

import {
    ECacheTag, ProfileCategoryDeleteRequest, ProfileCategoryGetListRequest,
    ProfileCategoryRaw, ProfileCategoryUpdateRequest, RestApiResponse,
} from '@ls-front/sharable';

import { ApiService } from './api.service';

@Injectable()
export class ProfileCategoryService {
    constructor(private api: ApiService) {
    }

    public getList(request?: ProfileCategoryGetListRequest): Observable<ProfileCategoryRaw[]> {
        return this.api.getCached<ProfileCategoryRaw[]>('/rest_api_v3/profile-category/get-list', ECacheTag.area, request);
    }

    public update(request: ProfileCategoryUpdateRequest): Observable<RestApiResponse> {
        return this.api.updateCached<RestApiResponse>('/rest_api_v3/profile-category/update', ECacheTag.area, request)
            .pipe(map(data => new RestApiResponse(data)));
    }

    public remove(request: ProfileCategoryDeleteRequest): Observable<RestApiResponse> {
        return this.api.updateCached<RestApiResponse>('/rest_api_v3/profile-category/delete', ECacheTag.area, request)
            .pipe(map(data => new RestApiResponse(data)));
    }
}
